/* mobile */
/* remove hovers, change projects desc width, proj padding on bottom/top, last club project width, home widths, switch from nav links in footer */
@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100% !important;
  }

  .about-container {
    width: 90% !important;
  }

  .clubproj-startcont {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }

  .clubproj-endcont {
    padding-right: 0px !important;
    padding-left: 10px !important;
  }

  .clubproj-middlecont {
    max-width: 150px !important;
  }

  .clubproj-midtopcont {
    padding-bottom: 20px !important;
  }

  .abtreal-container {
    width: 90% !important;
  }

  .abtreal-container2 {
    width: 100% !important;
  }

  .project-desc {
    width: 90% !important;
  }

  .proj {
    padding-bottom: 70px !important;
  }
}

@media (hover: hover) {
  .nav-brand:hover {
    color: rgb(150, 150, 150) !important;
  }

  .nav-links a:hover {
    color: rgb(150, 150, 150) !important;
  }

  .nav-contact a:hover {
    color: rgb(150, 150, 150) !important;
  }

  .about-button1 a {
    transition: all 0.15s ease-in-out 0s;
    text-decoration: none;
  }

  .about-button1 a:hover {
    transform: scale(1.05);
    color: white;
  }

  #home-btn1:hover {
    transform: scale(1.05);
    color: white;
  }

  #meeting-btn:hover {
    background-color: rgb(0, 0, 0) !important;
    transform: scale(1.05);
  }

  #meeting-btn:hover span {
    color: white !important;
  }

  .footer-links a:hover {
    color: black;
  }

  .footer-socials img:hover {
    filter: invert(43%) sepia(0%) saturate(367%) hue-rotate(136deg)
      brightness(94%) contrast(96%);
  }

  .abtreal-techrow img:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  .proj img:hover {
    transform: scale(1.1);
  }

  #link:hover {
    border-bottom-width: 0px;
  }

  .about-button1 a:hover {
    transform: scale(1.05);
    color: white;
  }
}

/* nav and gen */

.wrapper {
  margin: auto;
  width: 90%;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 150px;
}
.nav-brand {
  display: flex;
  font-weight: 500;
  font-size: 17px;
  align-items: center;
  color: rgb(0, 0, 0);
  text-decoration: none !important;
  padding: 10px 20px;
  position: relative !important;
  transition: all 0.15s ease-in-out 0s;
}

.nav-links {
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: color 200ms ease-in-out;
  /* gray border */
  margin: auto;
  border-radius: 25px;
  background: #f5f5f5;
  padding: 7px;
  width: auto;
  height: auto;
}

.nav-links a {
  color: rgb(0, 0, 0);
  text-decoration: none !important;
  font-weight: 600;
  padding: 6px 10px;
  line-height: 25px;
  margin: 0 6px;
  transition: all 0.15s ease-in-out 0s;
  z-index: 1;
  border-radius: 15px;
}

.nav-links a:active,
.nav-links a.active {
  color: black;
  background: #ffffff;
}

.nav-contact {
  display: flex;
  font-weight: 500;
  font-size: 17px;
  align-items: center;
  padding: 10px 20px;
  position: relative !important;
}

.nav-contact a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: all 0.15s ease-in-out 0s;
}

/* mobile nav */

.navbar-nav a {
  font-size: 30px;
  font-weight: 500;
}

/* home start */

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  margin: auto;
}

.profile-image {
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 20px;
}

.name-bold {
  color: black;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 60px;
  font-weight: bold;
  padding-bottom: 10px;
}

.job-role {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: rgb(88, 88, 88);
  padding-bottom: 70px;
}

.description {
  display: flex;
  text-align: center;
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.7);
  padding-bottom: 60px;
}

.about-button1 {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}

/* button stuff for hover */

.about-button1 a {
  transition: all 0.15s ease-in-out 0s;
  text-decoration: none;
}

.contact-info {
  color: black;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 20px;
}

.contact-description {
  display: flex;
  text-align: center;
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.7);
  padding-bottom: 50px;
}

.about-buttons2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 40px;
}

.about-buttons2 div {
  margin-bottom: 10px;
}

#home-btn1 {
  transition: all 0.15s ease-in-out 0s;
  text-decoration: none;
}

/* hand wave animation */
#wave-emoji {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
  padding-right: 5px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

#meeting-btn {
  transition: all 0.15s ease-in-out 0s;
}

/* footer start */
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 60px;
  padding-bottom: 10px;
}

.footer-links {
  display: flex;
  justify-content: center;
}

.footer-links a {
  padding-left: 12px;
  padding-right: 12px;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  text-decoration: none !important;
  color: black;
}

.footer-socials {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  transition: all 0.15s ease-in-out 0s;
}

.footer-socials img {
  margin: 10px;
}

.copyright {
  display: flex;
  justify-content: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

/* about start */

.abtreal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  margin: auto;
}

.abtreal-boldtitle {
  color: black;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 50px;
  font-weight: bold;
  padding-top: 50px;
  padding-bottom: 10px;
}

.abtreal-desc {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  padding-top: 30px;
}

.abtreal-desc span {
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.7);
  padding-bottom: 25px;
}

.abtreal-techtitle {
  color: black;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  padding-top: 50px;
  padding-bottom: 10px;
}

.abtreal-techcontainer {
  padding-top: 40px;
  padding-bottom: 40px;
}

.abtreal-techrow {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding-top: 20px;
  padding-bottom: 20px;
  flex: 1;
}

.abtreal-techrow img {
  margin: 2px;
  background: rgb(255, 255, 255);
  padding: 10px;
  width: 70px;
  height: 70px;
  transition: all 0.1s ease-in-out 0s;
}

.clubproj-list {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  flex: 1;
}

.clubproj-container {
  display: flex;
  justify-content: space-between;
  border: 1px rgb(236, 236, 236);
  border-style: solid none;
  padding: 35px 0;
  flex: 1;
}

.clubproj-startcont {
  margin: auto;
  font-size: 1.125rem;
  padding-left: 60px;
  padding-right: 60px;
  color: rgb(109, 109, 109);
}

.clubproj-middlecont {
  text-align: center;
}

.clubproj-midtopcont {
  padding-bottom: 15px;
  text-align: left;
}

.clubproj-bottomcont {
  text-align: left;
  width: 100%;
  max-width: 600px;
  color: rgb(109, 109, 109) !important;
}

.clubproj-endcont {
  /* experiment with centering */
  margin: auto;
  letter-spacing: 0.02em;
  font-size: 1.5rem;
  padding-left: 60px;
  padding-right: 60px;
  color: rgb(109, 109, 109) !important;
}

.clubproj-title {
  color: black;
  font-size: 22px;
  font-weight: bold;
  padding-right: 20px;
}

.clubproj-language {
  color: rgb(126, 126, 126);
}

.abtreal-container2 {
  width: 90%;
  margin: auto;
  padding-bottom: 30px;
}

/* projects container */

.projects-container {
  display: flex;
  flex-flow: column;
  width: 95%;
  margin: auto;
}

.project-boldtitle {
  color: black;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: bold;
  padding-top: 50px;
}

.project-desc {
  display: flex;
  padding-bottom: 60px;
  padding-top: 30px;
  color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  text-align: center;
  font-size: 1.125rem;
  width: 30%;
  margin: auto;
}

.projects-projcont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 60px;
  padding-bottom: 80px;
  align-items: baseline;
}

.proj {
  display: flex;
  flex-flow: column;
  justify-content: center;
  max-width: 570px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
}

.proj img {
  object-fit: contain;
  width: 100%;
  length: auto;
  transition: transform 0.5s ease;
}

.proj-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 35px;
  padding-top: 30px;
}

.proj-titles > span {
  text-align: left;
  line-height: 1.15;
  font-size: 2rem;
  font-weight: 500;
}

.proj-desc {
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
  line-height: 180%;
  letter-spacing: 0.02rem;
  padding-bottom: 25px;
}

#link {
  color: black;
  font-weight: 500;
  text-decoration: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  transition: all 0.05s linear 0s;
}

.proj-labels {
  text-align: left;
  padding: 0 !important;
}

.proj-labels li {
  float: left;
  display: inline;
  color: rgb(105, 105, 105);
  font-size: 13px;
  padding-right: 10px;
}
